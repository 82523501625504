
function PageNotFound() {
    return(

        <div className="absolute top-0 left-0 bg-white h-full w-full text-red-700 flex justify-center items-center">
            <div>
                <h1 className="text-3xl font-bold">PageNot found - 404</h1>
            </div>
        </div>
        
    )
}

export default PageNotFound